var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ShowPopup',{ref:"showRelatorio",attrs:{"propTitle":"Relatório"}},[_c('v-card',[_c('v-card-title',[_c('ImprimirTabelaComponent',{staticStyle:{"float":"right"},attrs:{"propRef":[_vm.tabelaRef],"propDashboardFinanceiro":true}})],1),_c('v-card-text',[_c('v-text-field',{staticClass:"mb-1",attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{ref:"tabela",attrs:{"search":_vm.search,"headers":_vm.detailHeaders,"items":_vm.detailItems,"footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","fixed-header":"","height":"87vh","dense":""},scopedSlots:_vm._u([{key:"item.contato_1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.contato_1))+" ")]}},{key:"item.contato_2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.contato_2))+" ")]}},{key:"item.contato_3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.contato_3))+" ")]}}],null,true)})],1)],1)],1),_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Relatórios")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.relatorioItems.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.relatorioHeaders,"items":_vm.relatorioItems,"footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.showReport(item)}}},[_vm._v("mdi-eye")])]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }