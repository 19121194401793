import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ShowPopup from '@/components/ShowPopup.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import { relatorioStore, mainStore, companyStore } from '@/store';
import _ from 'lodash';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.relatorioHeaders = [
            {
                text: 'Departamento',
                sortable: true,
                value: 'departamento_nome',
                align: 'left',
                type: 'varchar',
            },
            {
                text: 'Título',
                sortable: true,
                value: 'title',
                align: 'left',
                type: 'varchar',
            },
            {
                text: '',
                value: 'actions',
                align: 'left',
            },
        ];
        this.search = '';
        this.loading = false;
        this.filters = [];
        this.inputFields = {};
        this.tabelaRef = null;
    }
    get companies() {
        return companyStore.companies;
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    get relatorioItems() {
        return relatorioStore.relatorios;
    }
    get detailHeaders() {
        // @ts-ignore
        return relatorioStore.relatorioResult.headers;
    }
    get detailItems() {
        // @ts-ignore
        return relatorioStore.relatorioResult.items;
    }
    async showReport(item) {
        this.loading = true;
        const form = this.$refs.showRelatorio;
        form.show();
        relatorioStore.setRelatorioResult([]);
        let valid = true;
        await Object.keys(this.inputFields).forEach((key) => {
            const value = this.inputFields[key];
            if (_.isNull(value) || _.isUndefined(value)) {
                valid = false;
                return;
            }
        });
        if (!valid) {
            this.$swal({
                text: 'Para gerar o relatório você precisa preencher os filtros!',
                icon: 'warning',
            });
        }
        await relatorioStore.executeRelatorio({
            id: item.id,
            data: this.inputFields,
        });
        this.loading = false;
        // tem que ser depois do this.loading = false;, pois já desenhou na tela a tabela v-data-table
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async mounted() {
        await relatorioStore.getRelatorios();
    }
};
List = __decorate([
    Component({
        components: {
            ShowPopup,
            ImprimirTabelaComponent,
        },
    })
], List);
export default List;
